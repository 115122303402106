import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import AppForm, {
  FieldTypes,
  FieldsProps,
  FormSectionType,
} from "../../components/form/app-form";
import { EyeOutlined } from "@ant-design/icons";
import useFetch, { BaseUrl, useSuccess } from "../../common/hooks/use-fetch";
import { TextboxProps } from "../../components/form/app-textbox";
import useQuery from "../../common/hooks/use-query";
import { useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../../common/root-context";
import { Button, Card, Col, Row, Switch, Tooltip } from "antd";
import { ProfileDesign } from "../profile/view-theme-form";

const linkNames = [
  "Website",
  "Website2",
  "Portfolio",
  "Review",
  "GoogleMap",
  "Instagram",
  "Facebook",
  "Snapchat",
  "Twitter",
  "Youtube",
  "TikTok",
  "Linkedin",
  "SnackVideo",
  "Skype",
  "Fiverr",
  "Upwork",
  "Calendly",
  "AndroidApp",
  "IosApp",
];

const paymentMethodNames = [
  "Bank",
  "EasyPaisa",
  "JazzCash",
  "Zindigi",
  "Sadapay",
  "Nayapay",
];

const contactTypes = ["Default", "Contact2", "Contact3", "WhatsApp", "WhatsAppBusiness"];
const banners = ["1", "2", "3", "4", "5"];
const videos = ["1", "2", "3", "4", "5"];

export default function UserProfileForm(
  props: PropsWithChildren<ProfileFromPropsType>
) {
  const navigate = useNavigate();
  const [profile, setProfile] = useState<any>({
    ContactNumber_WhatsApp: "+92",
  });
  const [blockCard, setBlockCard] = useState(false);
  const { userId } = useQuery();
  const location = useLocation();
  const data = location.state;
  const { post, get } = useFetch();
  const success = useSuccess();
  const user = useUser();
  useEffect(
    function () {
      const uId = props.userId || userId;
      if (uId) {
        (async function () {
          const temp = await get(`/profile/detail-by-user?userId=${uId}`);
          if (temp) {
            const tempProfile = temp as ProfileType;
            if (
              tempProfile.EmailAddresses.length < 1 &&
              tempProfile.UserId === user?.Id
            ) {
              tempProfile.EmailAddresses = [
                { Email: user?.Email || "", IsActive: false, IsPrimary: false },
              ];
            }
            setProfile(transformProfile(tempProfile));
            if(tempProfile.Status == 21){
              setBlockCard(true);
            }else{
              setBlockCard(false);
            }
          }
        })();
        return;
      }
      setProfile({
        ContactNumber_WhatsApp: "+92",
      });
    },
    [userId]
  );
  async function saveUser(data: any) {
    const files: File[] = [];
    banners.forEach((x) =>
      files.push({
        FileType: FileTypes.Banner,
        IsExternal: false,
        Path: data["Banner_" + x],
      })
    );
    videos.forEach((x) =>
      files.push({
        FileType: FileTypes.Video,
        IsExternal: true,
        Path: data["Video_" + x],
      })
    );
    const profileUpdated: ProfileType = {
      Id: profile?.Id,
      FirstName: data.FirstName,
      LastName: data.LastName,
      Tagline: data.Tagline,
      GetLeads: data.GetLeads,
      Status: data.Status,
      OrganizationName: data.OrganizationName,
      Designation: data.Designation,
      Description: data.Description,
      ApplyPaymentsToAll: data.ApplyPaymentsToAll,
      ApplyLinksToAll: data.ApplyLinksToAll,
      /*  ApplyBannersToAll: data.ApplyBannersToAll,
      ApplyVideosToAll: data.ApplyVideosToAll, */
      Photo: data.Photo,
      Addresses: [
        {
          City: data.City_1,
          Country: data.Country_1,
          IsActive: true,
          Line1: data.Line1_1,
        },
      ],
      Contacts: contactTypes
        .map((x) => ({
          ContactNumber: data["ContactNumber_" + x],
          IsActive: true,
          ContactType: x,
        }))
        .filter((x) => x.ContactNumber),
      EmailAddresses: [
        { Email: data.Email_1, IsActive: true, IsPrimary: true },
      ],
      Links: linkNames
        .map((x) => ({
          LinkText: data["Link_" + x],
          IsUrl: true,
          IsActive: true,
          Platform: x,
        }))
        .filter((x) => x.LinkText),
      PaymentMethods: paymentMethodNames
        .map((x) => ({
          AccountTitle: data["PaymentMethodTitle_" + x],
          AccountNumber: data["PaymentMethodAccountNumber_" + x],
          IsActive: true,
          MethodName: x,
        }))
        .filter((x) => x.AccountNumber),
      Files: files.filter((x) => x.Path),
      // OrganizationId: 1,
    };

    if (props.userId) await post(`/profile/my/save`, profileUpdated);
    else if (user?.IsSuperAdmin || user?.IsAdmin)
      await post("/profile/save", profileUpdated);
    else if (user?.IsManager)
      await post(`/profile/save/${user?.OrganizationId}`, profileUpdated);
    else await post(`/profile/my/save`, profileUpdated);
    if (user?.IsSuperAdmin || user?.IsAdmin || user?.IsManager) {
      navigate("/users");
      return;
    }
    success("Profile updated successfully.");
  }

  const sections: FormSectionType[] = useMemo(() => {
    const isManager = user?.IsAdmin || user?.IsSuperAdmin || user?.IsManager;
    const tempSec = [
      {
        fields: genSection,
        title: "Basic Details",
        hidden: !isManager && profile.locked,
      },
      {
        fields: linksSec,
        title: "Social Links",
        hidden: !isManager && profile.LinksLocked,
      },
      {
        fields: paymentSec,
        title: "Payment Details",
        hidden: profile.PaymentMethodsLocked,
      },
      {
        fields: bannersSec,
        title: "Banners",
      },
      {
        fields: videosSec,
        title: "Videos",
      },
    ];
    // console.log(profile);
    // if (isManager || !profile.Locked) {
    //   tempSec.push();
    // }
    // if (isManager || !profile.LinksLocked) {
    //   tempSec.push();
    // }
    // if (isManager || !profile.PaymentMethodsLocked) {
    //   tempSec.push();
    // }
    return tempSec;
  }, [profile, user]);

  async function handleDeactivate(checked: boolean): Promise<void> {
   const temp = await post('/profile/block-card', {Id: profile.Id, BlockCard: checked})
   setBlockCard(checked);
   success(checked? "Card Deactivated": "Card Reactivated")
  }

  return (
    <>
      <Card>
        <Row align="middle">
          <Col sm={20} xs={24}>
            <label style={{ fontWeight:"bold"}}>Deactivate my Card:&nbsp;  </label>
            <Switch
              checked={blockCard}
              onChange={handleDeactivate}
            />
          </Col>
          <Col sm={4} xs={24} style={{display: 'flex', justifyContent: 'flex-end'}}>
            <Tooltip title="Click to View Saved Profile">
              <Button
                icon={<EyeOutlined />}
                onClick={() => {
                  window.open(data ? `${BaseUrl}/${data.OrgSubDomain}/${data.Id}-${data.Slug}` :
                    `${BaseUrl}/${user?.OrgSubDomain}/${user?.Id}-${user?.Slug}`,
                    "_blank", "width=400,height=600")}}>
                View Profile
              </Button>
            </Tooltip>
          </Col>
        </Row>
      </Card>
      <AppForm
        sections={sections}
        onSubmit={saveUser}
        key={profile.Id}
        defaultData={profile}
      />
    </>
  );
}

function transformProfile({
  Addresses,
  Contacts,
  Links,
  EmailAddresses,
  Files,
  PaymentMethods,
  ...rest
}: ProfileType): ProfileFormType {
  const data = Addresses.reduce<any>(function (ac, add: Address, index) {
    ac["Line1_" + (index + 1)] = add.Line1;
    ac["City_" + (index + 1)] = add.City;
    ac["Country_" + (index + 1)] = add.Country;
    return ac;
  }, {});
  Contacts.reduce<any>(function (ac, cont: Contact, index) {
    ac["ContactNumber_" + cont.ContactType] = cont.ContactNumber;
    return ac;
  }, data);
  Links.reduce<any>(function (ac, link: Link, index) {
    ac["Link_" + link.Platform] = link.LinkText;
    return ac;
  }, data);
  EmailAddresses.reduce<any>(function (ac, email: EmailAddress, index) {
    ac["Email_" + (index + 1)] = email.Email;
    return ac;
  }, data);
  Files.filter((x) => x.FileType === FileTypes.Banner).reduce<any>(function (
    ac,
    file: File,
    index
  ) {
    ac[file.FileType + "_" + (index + 1)] = file.Path;
    return ac;
  },
    data);
  Files.filter((x) => x.FileType === FileTypes.Video).reduce<any>(function (
    ac,
    file: File,
    index
  ) {
    ac[file.FileType + "_" + (index + 1)] = file.Path;
    return ac;
  },
    data);
  PaymentMethods.reduce<any>(function (ac, method: PaymentMethod, index) {
    ac["PaymentMethodAccountNumber_" + method.MethodName] =
      method.AccountNumber;
    ac["PaymentMethodTitle_" + method.MethodName] = method.AccountTitle;
    return ac;
  }, data);
  const p: ProfileFormType = {
    Id: rest.Id,
    FirstName: rest.FirstName,
    LastName: rest.LastName,
    Tagline: rest.Tagline,
    GetLeads: rest.GetLeads,
    OrganizationName: rest.OrganizationName,
    Designation: rest.Designation,
    Description: rest.Description,
    Photo: rest.Photo,
    Locked: rest.Locked,
    Status: rest.Status,
    LinksLocked: rest.LinksLocked,
    PaymentMethodsLocked: rest.PaymentMethodsLocked,
    ...data,
  };
  return p;
}

const genSection: FieldsProps[] = [
  {
    name: "Photo",
    label: "Photo",
    type: FieldTypes.upload,
  },
  {
    name: "FirstName",
    label: "Full Name",
    placeholder: "Muhammad Ali",
    type: FieldTypes.text,
    rules: [{ required: true, message: "Please input first name!" }],
  } as TextboxProps,
  // {
  //   name: "LastName",
  //   label: "Last Name",
  //   placeholder: "Ali",
  //   type: FieldTypes.text,
  //   rules: [{ required: true, message: "Please input last name!" }],
  // } as TextboxProps,
  {
    name: "Tagline",
    label: "Tagline",
    placeholder: "Hi, I'm",
    type: FieldTypes.text,
  } as TextboxProps,
  {
    name: "OrganizationName",
    label: "Organization",
    placeholder: "Digital Business Card",
    hidden: (_, { user }) =>
      !(user?.OrgSubDomain === 'dbc') || user?.IsSuperAdmin || user?.IsAdmin || user?.IsManager,
    type: FieldTypes.text,
  } as TextboxProps,
  {
    name: "Designation",
    label: "Designation",
    placeholder: "CEO",
    type: FieldTypes.text,
  } as TextboxProps,
  {
    name: "Description",
    label: "Description",
    placeholder: "Aim / Moto",
    type: FieldTypes.text,
  } as TextboxProps,
  {
    name: "ContactNumber_Default",
    label: "Contact Number",
    placeholder: "03001234567",
    type: FieldTypes.text,
  },
  {
    name: "ContactNumber_Contact2",
    label: "Contact Number 2",
    placeholder: "03001234567",
    type: FieldTypes.text,
    hidden: (_, { user }) => !user?.Plan?.includes("Pro"),
  },
  {
    name: "ContactNumber_Contact3",
    label: "Contact Number 3",
    placeholder: "03001234567",
    type: FieldTypes.text,
    hidden: (_, { user }) => !user?.Plan?.includes("Pro"),
  },
  {
    name: "ContactNumber_WhatsApp",
    label: "WhatsApp (Personal)",
    placeholder: "+923001234567",
    type: FieldTypes.text,
  },
  {
    name: "ContactNumber_WhatsAppBusiness",
    label: "WhatsApp Business",
    placeholder: "+923001234567",
    type: FieldTypes.text,
  },
  {
    name: "Email_1",
    label: "Email",
    placeholder: "name@domain.com",
    type: FieldTypes.text,
  },
  {
    name: "Line1_1",
    label: "Address",
    placeholder: "hs#10, st#12, Islamabad",
    type: FieldTypes.text,
  },
  {
    name: "City_1",
    label: "City",
    placeholder: "Islamabad",
    type: FieldTypes.text,
  },
  {
    name: "Country_1",
    label: "Country",
    placeholder: "Pakistan",
    type: FieldTypes.text,
  },
  {
    name: "GetLeads",
    label: "Get Leads",
    type: FieldTypes.switch,
    hidden: (_, { user }) => !user?.Plan?.includes("Pro"),
  },
];
const linksSec: FieldsProps[] = [
  {
    name: "ApplyLinksToAll",
    label: "Apply To All Users of Organization",
    type: FieldTypes.switch,
    hidden: (_, { user }) =>
      !user?.IsSuperAdmin && !user?.IsAdmin && !user?.IsManager,
  },
  {
    name: "Link_Website",
    label: "Website",
    type: FieldTypes.text,
    placeholder: "https://www.website.com",
  },
  {
    name: "Link_Website2",
    label: "Website 2",
    type: FieldTypes.text,
    placeholder: "https://www.website.com",
    hidden: (_, { user }) => !user?.Plan?.includes("Pro"),
  },
  {
    name: "Link_Instagram",
    label: "Instagram",
    type: FieldTypes.text,
    placeholder: "https://www.instagram.com/instagram",
  },
  {
    name: "Link_Facebook",
    label: "Facebook",
    type: FieldTypes.text,
    placeholder: "https://www.facebook.com/facebook",
  },
  {
    name: "Link_Snapchat",
    label: "Snapchat",
    type: FieldTypes.text,
    placeholder: "https://www.snapchat.com/add/ahmad",
  },
  {
    name: "Link_Twitter",
    label: "Twitter",
    type: FieldTypes.text,
    placeholder: "https://twitter.com/twitter",
  },
  {
    name: "Link_Youtube",
    label: "Youtube",
    type: FieldTypes.text,
    placeholder: "https://www.youtube.com/@youtube",
  },
  {
    name: "Link_TikTok",
    label: "TikTok",
    type: FieldTypes.text,
    placeholder: "https://www.tiktok.com/@tiktok",
  },
  {
    name: "Link_Linkedin",
    label: "Linkedin",
    type: FieldTypes.text,
    placeholder: "https://www.linkedin.com/in/linkedin",
  },
  {
    name: "Link_SnackVideo",
    label: "SnackVideo",
    type: FieldTypes.text,
    placeholder: "@snackvideo",
  },
  {
    name: "Link_Skype",
    label: "Skype",
    type: FieldTypes.text,
    placeholder: "@ali",
  },
  {
    name: "Link_Fiverr",
    label: "Fiverr",
    type: FieldTypes.text,
  },
  {
    name: "Link_Upwork",
    label: "Upwork",
    type: FieldTypes.text,
  },
  {
    name: "Link_Calendly",
    label: "Calendly",
    type: FieldTypes.text,
  },
  {
    name: "Link_AndroidApp",
    label: "Android App",
    type: FieldTypes.text,
  },
  {
    name: "Link_IosApp",
    label: "IOS App",
    type: FieldTypes.text,
  },
  {
    name: "Link_Portfolio",
    label: "Portfolio",
    type: FieldTypes.text,
    placeholder: "https://www.portfolio.com",
  },
  {
    name: "Link_Review",
    label: "Review Link",
    type: FieldTypes.text,
    placeholder: "https://www.review.com",
  },
  {
    name: "Link_GoogleMap",
    label: "Google Map Location",
    type: FieldTypes.text,
    placeholder: "https://maps.app.goo.gl/office",
  },
];
const bannersSec: FieldsProps[] = [
  /* {
    name: "ApplyBannersToAll",
    label: "Apply To All Users of Organization",
    type: FieldTypes.switch,
    hidden: (_, { user }) => !user?.IsSuperAdmin && !user?.IsAdmin && !user?.IsManager,
  }, */
  {
    name: "Banner_1",
    label: "Banner 1",
    type: FieldTypes.upload,
  },
  {
    name: "Banner_2",
    label: "Banner 2",
    type: FieldTypes.upload,
    hidden: (_, { user }) => !user?.Plan?.includes("Pro"),
  },
  {
    name: "Banner_3",
    label: "Banner 3",
    type: FieldTypes.upload,
    hidden: (_, { user }) => !user?.Plan?.includes("Pro"),
  },
  {
    name: "Banner_4",
    label: "Banner 4",
    type: FieldTypes.upload,
    hidden: (_, { user }) => !user?.Plan?.includes("Pro"),
  },
  {
    name: "Banner_5",
    label: "Banner 5",
    type: FieldTypes.upload,
    hidden: (_, { user }) => !user?.Plan?.includes("Pro"),
  },
];

const videosSec: FieldsProps[] = [
  /* {
    name: "ApplyVideosToAll",
    label: "Apply To All Users of Organization",
    type: FieldTypes.switch,
    hidden: (_, { user }) => !user?.IsSuperAdmin && !user?.IsAdmin && !user?.IsManager,
  }, */
  {
    name: "Video_1",
    label: "Video 1",
    type: FieldTypes.text,
  },
  {
    name: "Video_2",
    label: "Video 2",
    type: FieldTypes.text,
    hidden: (_, { user }) => !user?.Plan?.includes("Pro"),
  },
  {
    name: "Video_3",
    label: "Video 3",
    type: FieldTypes.text,
    hidden: (_, { user }) => !user?.Plan?.includes("Pro"),
  },
  {
    name: "Video_4",
    label: "Video 4",
    type: FieldTypes.text,
    hidden: (_, { user }) => !user?.Plan?.includes("Pro"),
  },
  {
    name: "Video_5",
    label: "Video 5",
    type: FieldTypes.text,
    hidden: (_, { user }) => !user?.Plan?.includes("Pro"),
  },
];
const paymentSec: FieldsProps[] = [
  {
    name: "ApplyPaymentsToAll",
    label: "Apply To All Users of Organization",
    type: FieldTypes.switch,
    hidden: (_, { user }) =>
      !user?.IsSuperAdmin && !user?.IsAdmin && !user?.IsManager,
  },
  {
    name: "PaymentMethodAccountNumber_Bank",
    label: "Bank Account Number",
    type: FieldTypes.text,
  },
  {
    name: "PaymentMethodTitle_Bank",
    label: "Bank Name",
    type: FieldTypes.text,
  },
  {
    name: "PaymentMethodAccountNumber_EasyPaisa",
    label: "EasyPaisa Number",
    type: FieldTypes.text,
  },
  {
    name: "PaymentMethodTitle_EasyPaisa",
    label: "EasyPaisa Title",
    type: FieldTypes.text,
  },
  {
    name: "PaymentMethodAccountNumber_JazzCash",
    label: "JazzCash Number",
    type: FieldTypes.text,
  },
  {
    name: "PaymentMethodTitle_JazzCash",
    label: "JazzCash Title",
    type: FieldTypes.text,
  },
  {
    name: "PaymentMethodAccountNumber_Zindigi",
    label: "Zindigi Number",
    type: FieldTypes.text,
  },
  {
    name: "PaymentMethodTitle_Zindigi",
    label: "Zindigi Title",
    type: FieldTypes.text,
  },
  {
    name: "PaymentMethodAccountNumber_Sadapay",
    label: "Sadapay Number",
    type: FieldTypes.text,
  },
  {
    name: "PaymentMethodTitle_Sadapay",
    label: "Sadapay Title",
    type: FieldTypes.text,
  },
  {
    name: "PaymentMethodAccountNumber_Nayapay",
    label: "Nayapay Number",
    type: FieldTypes.text,
  },
  {
    name: "PaymentMethodTitle_Nayapay",
    label: "Nayapay Title",
    type: FieldTypes.text,
  },
];

type ProfileFromPropsType = {
  userId?: number;
};
export type ProfileFormType = {
  Id?: number;
  FirstName: string;
  LastName: string;
  Email: string;
  CountryCode: string;
  Contact: string;
  Line1: string;
  City: string;
  Facebook: string;
  // ProviderCode: string;
};

export type ProfileType = {
  Id?: number;
  UserId?: number;
  FirstName: string;
  LastName: string;
  Tagline: string;
  OrganizationName: string;
  Designation: string;
  Description: string;
  Photo: string;
  // CardId: number;
  // Card: string;
  GetLeads: boolean;
  Status: number;
  Locked?: boolean;
  ApplyPaymentsToAll?: boolean;
  ApplyLinksToAll?: boolean;
  ApplyBannersToAll?: boolean;
  ApplyVideosToAll?: boolean;
  // ContactsLocked: boolean;
  // EmailAddressesLocked: boolean;
  // AddressesLocked: boolean;
  LinksLocked?: boolean;
  PaymentMethodsLocked?: boolean;
  Contacts: Contact[];
  EmailAddresses: EmailAddress[];
  Addresses: Address[];
  ProfileDesign?: ProfileDesign;
  Links: Link[];
  Files: File[];
  PaymentMethods: PaymentMethod[];
};

export type Address = {
  Line1: string;
  Line2?: string;
  City: string;
  Country: string;
  IsActive: boolean;
};

export type File = {
  Path: string;
  FileType: FileTypes;
  IsExternal: boolean;
};

enum FileTypes {
  Banner = "Banner",
  Video = "Video",
}

export type Contact = {
  //CountryCode: string;
  ContactType: string;
  ContactNumber: string;
  IsActive: boolean;
};

export type EmailAddress = {
  Email: string;
  IsPrimary: boolean;
  IsActive: boolean;
};

export type Link = {
  LinkText: string;
  Platform?: string;
  IsUrl: boolean;
  IsActive: boolean;
};

export type PaymentMethod = {
  MethodName: string;
  AccountNumber: string;
  AccountTitle: string;
  IsActive: boolean;
};
/*

(function test() {
  let init = [];
  let rslt = [
    "Bank",
    "EasyPaisa",
    "JazzCash",
    "Zindgi",
    "Sadapay",
    "Nayapay",
  ].reduce((acc, x) => {
    acc = [
      ...acc,
      {
        name: "PaymentMethodAccountNumber_" + x,
        label: x +" Number",
        type:"FieldTypes.text",
      },
      {
        name: "PaymentMethodTitle_" + x,
        label: x +" Title",
        type:"FieldTypes.text",
      },
    ];
    return acc;
  }, init);
  console.log(JSON.stringify(rslt));
})();

*/
