import { Form, Switch } from "antd";
import { FieldsProps } from "./app-form";
import { SwitchChangeEventHandler } from "antd/es/switch";

export default function AppSwitch({
  children,
  label,
  name,
  type,
  rules,
  hidden,
  // onChange,
  ...props
}: React.PropsWithChildren<SwitchProps>) {
  return (
    <Form.Item name={name} label={label} rules={rules} valuePropName="checked">
      <Switch {...props} />
    </Form.Item>
  );
}

export interface SwitchProps extends FieldsProps {
  defaultChecked?: boolean | undefined;
  onChange?: SwitchChangeEventHandler;
}
