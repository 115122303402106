import { FC, PropsWithChildren } from "react";
import { Button, Form, Row, Col, Card, Space } from "antd";
import AppTextbox, { TextboxProps } from "./app-textbox";
import AppSwitch, { SwitchProps } from "./app-switch";
import { UserType, useUser } from "../../common/root-context";
import AppSelect, { AppSelectProps } from "./app-select";
import AppContactGroup from "./app-contact-group";
import AppEmailGroup from "./app-email-group";
import AppUpload, { AppUploadProps } from "./app-upload";
import AppDateTime from "./app-datetime";

export default function AppForm({
  reset,
  masterData,
  submitText,
  form,
  ...props
}: PropsWithChildren<FormProps>) {
  const user = useUser();
  const onFinish = (values: any) => {
    props.onSubmit(values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={props.defaultData}
      onValuesChange={props.onValuesChange}
      //   requiredMark={requiredMark}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      {props.sections?.map((sec) => (
        // <Space size={12}>
        <Card
          title={sec.title}
          bordered={true}
          key={sec.title}
          style={{ marginTop: "8px", display: sec.hidden ? "none" : undefined }}
        >
          <Row gutter={[16, 0]}>
            {sec.fields?.map((field) =>
              renderField(field, props.defaultData || {}, {
                user,
                masterData,
              })
            )}
          </Row>
        </Card>
        // </Space>
      ))}
      <Row gutter={[16, 0]}>
        {props.fields?.map((field) =>
          renderField(field, props.defaultData || {}, { user, masterData })
        )}
      </Row>
      <Form.Item
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingTop: "8px",
        }}
      >
        {reset && (
          <Button onClick={reset} style={{ marginRight: "8px" }}>
            Reset
          </Button>
        )}
        <Button type="primary" htmlType="submit">
          {submitText || "Submit"}
        </Button>
      </Form.Item>
      {/* <Row>
      </Row> */}
    </Form>
  );
}
function renderField(
  { hidden, ...field }: FieldsProps,
  defaultData: any,
  { user, masterData }: { user: UserType | null | undefined; masterData: any }
): JSX.Element | undefined {
  if (hidden && hidden(defaultData, { user })) {
    return;
  }
  if (field.type === FieldTypes.text) {
    return (
      <FieldWrapper key={field.name}>
        <AppTextbox
          {...(field as TextboxProps)}
          //defaultValue={defaultData[field.name]}
        />
      </FieldWrapper>
    );
  }
  if (field.type === FieldTypes.switch) {
    return (
      <FieldWrapper key={field.name}>
        <AppSwitch
          {...(field as SwitchProps)}
          //defaultChecked={defaultData[field.name]}
        />
      </FieldWrapper>
    );
  }
  if (field.type === FieldTypes.select) {
    const fieldProps = field as AppSelectProps;
    return (
      <FieldWrapper key={field.name}>
        <AppSelect
          {...fieldProps}
          //defaultValue={defaultData[field.name]}
          options={
            fieldProps.getOptions && fieldProps.getOptions(masterData || {})
          }
        />
      </FieldWrapper>
    );
  }
  if (field.type === FieldTypes.upload) {
    const fieldProps = field as AppUploadProps;
    return (
      <FieldWrapper key={field.name}>
        <AppUpload {...fieldProps} />
      </FieldWrapper>
    );
  }
  if (field.type === FieldTypes.date) {
    return (
      <FieldWrapper key={field.name}>
        <AppDateTime
          {...(field as TextboxProps)}
          //defaultValue={defaultData[field.name]}
        />
      </FieldWrapper>
    );
  }
  // if (field.type === FieldTypes.contact) {
  //   // const fieldProps = field as AppSelectProps;
  //   return (
  //     <FieldWrapper key={field.name} span={24}>
  //       <AppContactGroup {...field} />
  //     </FieldWrapper>
  //   );
  // }
  // if (field.type === FieldTypes.email) {
  //   // const fieldProps = field as AppSelectProps;
  //   return (
  //     <FieldWrapper key={field.name} span={24}>
  //       <AppEmailGroup {...field} />
  //     </FieldWrapper>
  //   );
  // }
  return <></>;
}

const FieldWrapper: FC<any> = (props): JSX.Element => {
  return (
    <Col xs={24} md={12} lg={6}>
      {props.children}
    </Col>
  );
};

export interface FormProps {
  form?: any;
  fields?: FieldsProps[];
  sections?: FormSectionType[];
  onSubmit: any;
  onValuesChange?: any;
  defaultData?: any;
  masterData?: any;
  submitText?: string;
  reset?: () => void;
}
export type FormSectionType = {
  fields: FieldsProps[];
  title: string;
  hidden?: boolean;
};

export interface FieldsProps {
  name?: string | (string | number)[];
  label: string;
  placeholder?: string;
  type?: FieldTypes;
  rules?: any;
  hidden?: (
    data: any,
    { user }: { user: UserType | null | undefined }
  ) => boolean;
  disabled?:boolean;
}

export enum FieldTypes {
  text = 10,
  switch = 11,
  select = 12,
  date = 13,
  upload = 20,
  contact = 101,
  email = 102,
  link = 103,
  address = 104,
  paymentMethod = 105,
}
