import React, { Children, useState } from "react";
import { LoadingOutlined, PlusOutlined, CloseCircleFilled } from "@ant-design/icons";
import { Form, Input, message, Upload } from "antd";
import type { UploadChangeParam } from "antd/es/upload";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { FieldsProps } from "./app-form";
import { useForm } from "antd/es/form/Form";
import "../../App.css"
import useFetch, { ApiBaseUrl, BaseUrl } from "../../common/hooks/use-fetch";

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const beforeUpload = (file: RcFile) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt1M = file.size / 1024 / 1024 < 1;
  if (!isLt1M) {
    message.error("Image must be smaller than 1MB!");
  }
  return isJpgOrPng && isLt1M;
};

const AppUpload: React.FC<AppUploadProps> = (props) => {
  const form = Form.useFormInstance();
  const val = form.getFieldValue(props.name as string)
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>(val);
  const { post } = useFetch();

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      const name = props.name as string;
      const token = info.file.response[0];
      form.setFieldsValue({ [name]: token.Path }); //.setFieldsValue({ [name]: token });
      // Get this url from response in real world.
      // getBase64(info.file.originFileObj as RcFile, (url) => {
      // });
      setLoading(false);
      setImageUrl(token.Path);
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  async function handleDelete(): Promise<void> {
    await post("/upload/delete", { Path: imageUrl, Item: props.name });
    setImageUrl("");
    form.setFieldValue(props.name as string, null);
  }

  return (
    <>
      <span>{props.label}:
        <span style={{ fontSize: "12px", color: "#ff1b1b9e" }}> &lt; 1Mb</span>
      </span>
      <div className="image" >
      {imageUrl &&
        <CloseCircleFilled className="image-delete-button" onClick={handleDelete} disabled={props.disabled}  />
      }
      <Upload
        name="files"
        listType="picture-card"
        className="avatar-uploader"
        disabled={props.disabled}
        showUploadList={false}
        action={`${ApiBaseUrl}/upload`}
        beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        {imageUrl ? (
          <img
            src={BaseUrl + imageUrl}
            alt="avatar"
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        ) : (
          uploadButton
        )}
      </Upload>
      <Form.Item name={props.name} hidden>
        <Input />
      </Form.Item>
      </div>
    </>
  );
};

export interface AppUploadProps extends FieldsProps { }

export default AppUpload;
