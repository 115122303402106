import React from "react";
import { Form, Select } from "antd";
import { FieldsProps } from "./app-form";
import { BaseOptionType, DefaultOptionType } from "antd/es/cascader";

export default function AppSelect({
  children,
  label,
  name,
  type,
  rules,
  //defaultValue,
  options,
  ...props
}: React.PropsWithChildren<AppSelectProps>) {
  return (
    <Form.Item name={name} label={label} rules={rules}>
      <Select
        showSearch
        // style={{ width: 200 }}
        placeholder="Search to Select"
        optionFilterProp="children"
        allowClear={true}
        filterOption={(input, option) => (option?.label ?? "").includes(input)}
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? "")
            .toLowerCase()
            .localeCompare((optionB?.label ?? "").toLowerCase())
        }
        options={options}
        onChange={props.onChange}
      />
    </Form.Item>
  );
}

export interface AppSelectProps extends FieldsProps {
  defaultValue?: boolean | undefined;
  options?: BaseOptionType[] | undefined;
  getOptions?: (masterData: any) => DefaultOptionType[];
  onChange?:
    | ((value: any, option: BaseOptionType | BaseOptionType[]) => void)
    | undefined;
}
